import { type MetaFunction, type LoaderFunctionArgs, json } from '@remix-run/node'
import { NavLink, useLoaderData} from '@remix-run/react'
import { IndianRupeeIcon, PlusIcon } from 'lucide-react'
import { PageContainer, PageBody } from '#app/components/ui/layout'
import { Separator } from '#app/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '#app/components/ui/tabs'
import { requireUserIdWithCheck } from '#app/utils/auth.server'
// import { getCurrentValue } from '#app/utils/calculators'
import { calculateInterest } from '#app/utils/calculators.ts'
import { TARRAKKI_STATUS_ACTIVE, TARRAKKI_STATUS_AWAITING_DOCUMENTS, TARRAKKI_STATUS_UNDER_REVIEW, TARRAKKI_STATUS_AWAITING_PAYMENT, TARRAKKI_STATUS_NEW, TARRAKKI_STATUS_MATURED, TARRAKKI_STATUS_REJECTED } from '#app/utils/constants'
import { prisma } from '#app/utils/db.server'
import { logger } from '#app/utils/logger.ts'
import { cn, getFdImgSrc } from '#app/utils/misc'
import { Badge } from "#components/ui/badge.tsx"
import { Card, CardContent, CardHeader } from "#components/ui/card.tsx"

const DEFAULT_SFB_STATUS = 'session-started'

export const meta: MetaFunction = () => [{ title: 'Booked FD | Super FD' }]

export async function loader({ request }: LoaderFunctionArgs){
  const userId = await requireUserIdWithCheck(request)
  logger.info({
    message: `users/bookedfd,user/${userId}`,
    labels: { origin: 'user_page' },
  })
  const userFds = await prisma.userFd.findMany({
    where : { userId, isDeleted: false},
    select: {
        id: true,
        status: true,
        amount: true,
        tenure: true,
        fdName: true,
        fdImageId: true,
        interest: true,
        isRbiInsured: true,
        tenureId: true,
        tenureYearFormat: true,
        appId: true,
        schemeId: true,
        maturityDate: true,
        maturityAmount: true,
        fdrDate: true,
        bookedDate: true,
        createdAt: true,
        fdSession: {
            select: {
                step: true,
                status: true
            }
        }
    },
    orderBy: {
        createdAt: 'desc'
    }
  })

    if(userFds.length !== 0){
        let totalInvestedAmount = 0
        let totalMaturityAmount = 0
        let totalInterestRate = 0

        const activeFDs = userFds.filter((fd) => fd.status === TARRAKKI_STATUS_ACTIVE)
    
        activeFDs.map(fd => {
          totalInvestedAmount = totalInvestedAmount + fd.amount
          totalMaturityAmount = totalMaturityAmount + (fd.maturityAmount ?? 0)
          totalInterestRate = totalInterestRate + fd.interest
          return null
        })
    
        let totalGains = totalMaturityAmount - totalInvestedAmount
        let avgInterestRate =  Number( totalInterestRate / activeFDs.length ).toFixed(1)

        return json({ userFds, activeStats: { totalInvestedAmount,totalMaturityAmount, totalInterestRate, totalGains, avgInterestRate }})
    }

    // const updatedUserFds = userFds.map(async (userFd) => {
    //     const appDetails = await getTarrakkiProviderApplication(userFd?.appId)
    //     console.log('appDetails : ', appDetails)
    //     if(appDetails.status === 'error') return userFd
    //     if(userFd.status === appDetails.data.status){
    //        return userFd
    //     }
    //     userFd.status = appDetails.data.status 
    //     return userFd
    // })

    return json({ userFds, activeStats: null })
}

export default function Index() {
  const data = useLoaderData<typeof loader>()
  console.log('data : ', data.userFds[0])
//   const navLinkDefaultClassName =
//   'w-full md:min-w-[70%] max-w-md block rounded-xl border bg-card text-card-foreground shadow text-base lg:text-xl hover:bg-accent'

  const activeFDs = data.userFds.filter((fd) => fd.status === TARRAKKI_STATUS_ACTIVE)
  const pendingFDs = data.userFds.filter((fd) => fd.status !== TARRAKKI_STATUS_ACTIVE)

  if(data.userFds.length === 0){
    return (
        <PageBody>
            <PageContainer>
            <div className="py-4 flex flex-col items-center justify-center text-center space-y-8">
            <h1 className="text-[40px] font-semibold">
             No Booked Fd
            </h1>
        </div>
            </PageContainer>
        </PageBody>
    )
  }

	return (
		<PageBody>
        <PageContainer className="px-0">
        <div className="py-4 flex flex-col items-center justify-center text-center space-y-8">
            {/* <h1 className="text-[40px] font-semibold">
             Your Booked FDs
            </h1> */}
            <h1 className="text-h3 md:text-[40px]">Your Booked FDs</h1>
        </div>

        <div>
        <Tabs defaultValue="pending">
            <div className="flex items-center justify-center px-4 py-2">
              {/* <h1 className="text-xl font-bold">FDs</h1> */}
              <TabsList className="">
                {/* <TabsTrigger value="all" className="text-zinc-600 dark:text-zinc-200">All</TabsTrigger> */}
				<TabsTrigger value="pending" className="">
                    Pending
                    <Badge  className="ml-2">{pendingFDs.length ?? 0}</Badge>
                </TabsTrigger>
                <TabsTrigger value="active" className="">
                    Active
                    <Badge  className="ml-2">{activeFDs.length ?? 0}</Badge>
                </TabsTrigger>
              </TabsList>
            </div>
			<TabsContent value="pending" className="m-0">
			  <BookedFdList fds={pendingFDs} />
            </TabsContent>
            <TabsContent value="active" className="m-0">
                <div className="py-2 flex flex-col items-center justify-center text-center space-y-8">
                    <h1 className="text-h6 md:text-[25px]">Investment Summary</h1>
                </div>

                {
                data.activeStats && activeFDs.length > 0 && (    
                <div className="p-2 md:p-4 mb-4 rounded-xl grid gap-2 md:gap-4 grid-cols-2 lg:grid-cols-4 bg-muted">
                    <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4 pb-0 lg:p-6 lg:pb-2">
                        <div className="text-muted-foreground text-sm lg:font-semibold">
                        Invested Amount
                        </div>
                        <IndianRupeeIcon className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent className="p-4 py-2 lg:p-6 lg:pt-0">
                        <div className="font-semibold lg:font-bold lg:text-2xl">&#8377; {new Intl.NumberFormat('en-In').format(Number(data.activeStats.totalInvestedAmount)) }</div>
                    </CardContent>
                    </Card>
                    <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4 pb-0 lg:p-6 lg:pb-2">
                        <div className="text-muted-foreground text-sm lg:font-semibold">
                        Maturity Amount
                        </div>
                        <IndianRupeeIcon className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent className="p-4 py-2 lg:p-6 lg:pt-0">
                        <div className="font-semibold lg:font-bold lg:text-2xl text-gain">&#8377; {new Intl.NumberFormat('en-In').format(Number(data.activeStats.totalMaturityAmount)) }</div>
                    </CardContent>
                    </Card>
                    <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4 pb-0 lg:p-6 lg:pb-2">
                        <div className="text-muted-foreground text-sm lg:font-semibold">Total Gains</div>
                        <div>
                        <PlusIcon className="h-3 w-3 text-muted-foreground inline" />
                        <IndianRupeeIcon className="h-4 w-4 text-muted-foreground inline" />
                        </div>
                    </CardHeader>
                    <CardContent className="p-4 py-2 lg:p-6 lg:pt-0">
                        <div className="font-semibold lg:font-bold lg:text-2xl text-gain">&#8377; {new Intl.NumberFormat('en-In').format(Number(data.activeStats.totalGains)) }</div>
                    </CardContent>
                    </Card>
                    <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 p-4 pb-0 lg:p-6 lg:pb-2">
                        <div className="text-muted-foreground text-sm lg:font-semibold">
                            Avg. Interest Rate
                        </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="h-4 w-4 text-muted-foreground"
                                >
                                <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                            </svg>
                    </CardHeader>
                    <CardContent className="p-4 py-2 lg:p-6 lg:pt-0">
                      <div className="font-semibold lg:font-bold lg:text-2xl">{(Number(data.activeStats.avgInterestRate ))} %</div>                    
                    </CardContent>
                    </Card>
                </div>
                 )
                }
              <BookedFdList fds={activeFDs} />
            </TabsContent>
          </Tabs>
        </div>

        </PageContainer>
        </PageBody>
  )

}

export type BookedFd = {
    id: string;
    status: string;
    bookedDate: string | null;
    maturityDate: string | null;
    maturityAmount?: number | null;
    fdrDate: string | null;
    tenureId: string;
    appId: string;
    schemeId: string;
    amount: number;
    tenure: number;
    tenureYearFormat: string;
    fdName: string;
    fdImageId: string;
    interest: number;
    isRbiInsured: boolean;
    createdAt: string;
    fdSession: {
        step: string,
        status: string
    } | null
}

export function BookedFdList({ fds }: { fds: BookedFd[]}){
    const navLinkDefaultClassName =
  'w-full md:min-w-[70%] max-w-md block rounded-xl border bg-card text-card-foreground shadow text-base lg:text-xl hover:bg-accent'

    return (
        <div className="grid gap-4 md:gap-10 md:grid-cols-2 lg:grid-cols-2 justify-items-center">
        { 
        fds.map(fd => {

        if(fd.status === TARRAKKI_STATUS_ACTIVE){
            return (
            <NavLink
            key={fd.id}
            to={fd.id}
            preventScrollReset
            prefetch="intent"
            className={({ isActive }) =>
                cn(navLinkDefaultClassName, isActive && 'bg-accent')
            }
        >
            <ActiveCard fd={fd} />
            </NavLink> 
            )
        }else{
           return (
           <NavLink
            key={fd.id}
            to={fd.id}
            preventScrollReset
            prefetch="intent"
            className={({ isActive }) =>
                cn(navLinkDefaultClassName, isActive && 'bg-accent')
            }
        >
        <Card 
        className="w-full md:min-w-[70%] max-w-md border-0"
        >
            <CardHeader className="justify-between w-full flex-row">
                <div className="flex space-x-2 align-center">
                    <div className="p-1 border shadow-sm rounded-lg">
                        <img
                        src={getFdImgSrc(fd.fdImageId)}
                        alt={fd.fdName}
                        className="h-10 w-10 object-cover rounded-full"
                        />
                    </div>
                    <div className="px-1 md:px-2 py-1 lg:px-2 self-center">
                        <div>{getMaxCharName(fd.fdName, 22)}</div>
                        <p className="text-xs text-muted-foreground">
                            {new Date(fd.createdAt).toLocaleString('en-In', {
                                timeZone: 'Asia/Kolkata',
                                year: "numeric",
                                month: "short",
                                day: "numeric"
                            })}
                        </p>
                    </div>
                </div>
        
                <div className="self-center">
                 {
                   fd.status === DEFAULT_SFB_STATUS 
                   ? (
                    <Badge variant="fd_pending" className="text-xs px-2 lg:px-3">{getSFBStatus(fd.fdSession?.step, fd.fdSession?.status) }</Badge>
                   )
                   :  getBookedFDStatusBadge(fd.status)
                 }
                </div>
            </CardHeader>
            <Separator  />
            <CardContent className="flex justify-around pt-2 space-x-4">
                <div>
                    <div className="">
                        <div className="text-muted-foreground text-sm">Amount</div>
                        <div>&#8377; {new Intl.NumberFormat('en-In').format(Number(fd.amount))}</div>
                    </div>
                </div>
                <Separator orientation='vertical' />
                <div>
                    <div className="">
                        <div className="text-muted-foreground text-sm">Tenure</div>
                        <div>{fd.tenureYearFormat}</div>
                    </div>
                </div>
                <Separator orientation='vertical' />
                <div>
                    <div className="">
                        <div className="text-muted-foreground text-sm">Interest Rate</div>
                        <div>{fd.interest}%</div>
                    </div>
                </div>
            </CardContent>
            </Card>
            </NavLink> 
           )
        }

     
           
        })
        }
        </div>
    )
}

function ActiveCard({ fd }: { fd: BookedFd}){

    const calculatedAmounts = calculateInterest({amount: fd.amount, interest: fd.interest, tenure: fd.tenureYearFormat, compoundingType: 'quarterly'})

    const daysFromStartToCurrent = getDiffInDaysFromTwoDate(fd.fdrDate ?? JSON.stringify(new Date()).split('T')[0], JSON.stringify(new Date()).split('T')[0])

    const daysFromStartToEnd = getDiffInDaysFromTwoDate(fd.fdrDate ?? JSON.stringify(new Date()).split('T')[0], fd.maturityDate ?? JSON.stringify(new Date()))

    return (
        <Card 
        className="w-full px-4 md:px-6 md:min-w-[70%] max-w-md border-0"
        >
            <CardHeader className="px-0 py-4 md:py-6 justify-between w-full flex-row">
                <div className="flex space-x-2 align-center">
                    <div className="p-1 border shadow-sm rounded-lg">
                        <img
                        src={getFdImgSrc(fd.fdImageId)}
                        alt={fd.fdName}
                        className="h-10 w-10 object-cover rounded-full"
                        />
                    </div>
                    <div className="px-1 md:px-2 self-center">
                        <div>{getMaxCharName(fd.fdName, 22)}</div>
                        <p className="text-xs text-muted-foreground">
                            {`${new Date(fd.createdAt).toLocaleString('en-In', {
                                timeZone: 'Asia/Kolkata',
                                year: "numeric",
                                month: "short",
                                day: "numeric"
                            })}, ${fd.tenureYearFormat}`}
                        </p>
                    </div>
                </div>

                <div className="self-center">
                 {
                    getBookedFDStatusBadge(fd.status)
                 }
                </div>
            </CardHeader>
            {/* <Separator  /> */}
            <div className="w-full bg-border rounded-full h-0.5">
            <div className={cn("bg-gain h-0.5 rounded-full", daysFromStartToCurrent > daysFromStartToEnd && 'bg-violet-500')} style={{ width: `${daysFromStartToEnd === 0 ? '0' : Math.floor((daysFromStartToCurrent / daysFromStartToEnd ) * 100) > 100 ? '100' : Math.floor((daysFromStartToCurrent / daysFromStartToEnd ) * 100)}%`}}></div>
            </div>
            <CardContent className="flex justify-around px-0 pt-4 md:pt-6 space-x-2 md:space-x-4">
                <div>
                    <div className="">
                        <div className="text-muted-foreground text-sm">Invested Amount</div>
                        <div>&#8377; {new Intl.NumberFormat('en-In').format(Number(fd.amount))}</div>
                    </div>
                </div>
                <Separator orientation='vertical' />
                <div>
                    <div className="">
                        <div className="text-muted-foreground text-sm">Maturity Amount</div>
                        <div className="text-gain">&#8377; {new Intl.NumberFormat('en-In').format(Number(fd.maturityAmount && fd.maturityAmount !== 0 ? fd.maturityAmount : calculatedAmounts.mAmt))}</div>
                    </div>
                </div>
                <Separator orientation='vertical' />
                <div>
                    <div className="">
                        <div className="text-muted-foreground text-sm">Interest Rate</div>
                        <div>{fd.interest}%</div>
                    </div>
                </div>
            </CardContent>
            </Card>
    )
}


export function getBookedFDStatusBadge(status: string){
  switch(status){
    case TARRAKKI_STATUS_NEW: {
      return <Badge variant="fd_pending" className="text-xs">Pending</Badge>
    }
    case TARRAKKI_STATUS_ACTIVE: {
      return <Badge variant="fd_ongoing" className="text-xs">Active</Badge>
    }
    case TARRAKKI_STATUS_AWAITING_PAYMENT:{
      return <Badge variant="fd_pending" className="text-xs">Payment Pending</Badge>
    }
    case TARRAKKI_STATUS_AWAITING_DOCUMENTS:{
      return <Badge variant="fd_ongoing" className="text-xs">Payment Success</Badge>
    }
    case TARRAKKI_STATUS_UNDER_REVIEW: {
      return <Badge variant="fd_pending" className="text-xs">Under Review</Badge>
    }
    case TARRAKKI_STATUS_REJECTED:{
      return <Badge variant="fd_pending" className="text-xs">Pending</Badge>
    }
    case TARRAKKI_STATUS_MATURED:{
      return <Badge variant="fd_matured" className="text-xs">Matured</Badge>
    }
    default: {
      return <Badge variant="fd_pending" className="text-xs">{status}</Badge>
    }
    
  }
}

// type Step = 'fd-booking' | 'pan' | 'aadhaar-number' | 'investor-details' | 'bank-account' | 'nominee-detail' | 'fd_application_review' | 'payment' | 'vkyc'

// type Status = 'started' | 'completed' | 'not_started' | 'error' | 'failed'

export function getSFBStatus(step: string | undefined, status: string | undefined): string{
    switch(step){
        case 'fd-booking': return status === 'completed' ? 'KYC Pending' : 'KYC Pending'
        case 'pan': return status === 'completed' ? 'KYC Pending' : 'KYC Pending'
        case 'aadhaar-number': return status === 'completed' ? 'Info Pending' : 'KYC Pending'
        case 'investor-details': return status === 'completed' ? 'Bank Pending' : 'Info Pending'
        case 'bank-account': return status === 'completed' ? 'Nominee Pending' : 'Bank Pending'
        case 'nominee-detail': return status === 'completed' ? 'Payment Pending' : 'Nominee Pending'
        case 'fd_application_review': return 'Payment Pending'
        case 'payment': return status === 'completed' ? 'In Progress' : 'Payment Pending'
        case 'vkyc': return 'In Progress'

        default: return 'Pending'
    }
}

export function getMaxCharName(charName: string, charLength: number ){
	if(charName.length < charLength){
		return charName
	}else{
        return charName.slice(0,17).concat('...')
	}
}

export function getDiffInDaysFromTwoDate(initialDate: string, finalDate: string) {
	const startDate = new Date(initialDate)
	const endDate = new Date(finalDate)

	let DiffInTime = endDate.getTime() - startDate.getTime()

	// Calculating the no. of days between two dates
	let DiffInDays = Math.round(DiffInTime / (1000 * 3600 * 24))

	return DiffInDays
}

export function convertDaysToYMD(days: number) {
    const years = Math.floor(days / 365);
    const remainingDaysAfterYears = days % 365;
    const months = Math.floor(remainingDaysAfterYears / 30);
    const remainingDays = remainingDaysAfterYears % 30;

    return `${years > 0 ? `${years}Y ` : ''}${months > 0 ? `${months}M ` : ''}${remainingDays > 0 ? `${remainingDays}D` : ''}`;
}